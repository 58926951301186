import React from "react"
import PropTypes from "prop-types"

import "./Layout.css"

const Layout = ({children, style, className}) => {
  return (
    <div className={className} style={style}>
      {children}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
